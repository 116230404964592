import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ImageIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Loader from '../components/loader/Loader';
import Paystack from '../components/modal/Paystack';
import Report from '../components/report/Report';
import { fetchAgents } from '../features/agents/agentsSlice';
import {
  assignDocumentsToAgents,
  getDocument,
  reset,
  sendLandDocumentStatusToBuyer,
} from '../features/landdocument/landdocumentSlice';
import { finalAgentPayment, initialAgentPayment } from '../features/payments/paymentSlice';
import { fetchSurveyors } from '../features/surveyors/surveyorsSlice';
import { maskAddress, maskEmail, maskPhoneNumber } from '../helpers/maskedFunctions';

const LandDocument = () => {
  const dispatch = useDispatch();
  const verifiedImg = '/assets/icons/verified.png';
  const unverifiedImg = '/assets/icons/unverified.png';
  const pendingImg = '/assets/icons/pending.png';
  const { document, isLoading, isError, isSuccess, message } = useSelector((state) => state.landdocument);
  const { agents } = useSelector((state) => state.agents);
  const { surveyors } = useSelector((state) => state.surveyors);
  const { docId } = useParams();
  const { user } = useSelector((state) => state.adminAuth);

  //  Paystack Functionalities

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleTransferInitiated = (transferDetails) => {
    if (document?.document?.AgentPaymentStatus === 'Unpaid') {
      dispatch(
        initialAgentPayment({
          reference: transferDetails.reference,
          docId: document?.document?._id,
          adminId: user?.id,
          agentId: document?.agent?._id,
        })
      );
    } else {
      dispatch(
        finalAgentPayment({
          reference: transferDetails.reference,
          docId: document?.document?._id,
          adminId: user?.id,
          agentId: document?.agent?._id,
        })
      );
    }
  };

  const [agentsLicense, setAgents] = React.useState('');

  const handleChange = (event) => {
    setAgents(event.target.value);
  };

  const HandleAssign = () => {
    Swal.fire({
      title: 'Do you want to Assign  this Agent for this validation?',
      showCancelButton: true,
      confirmButtonText: 'Assign',
    }).then((result) => {
      if (result.isConfirmed) {
        const checkagent = surveyors.find((surveyor) => surveyor.License === agentsLicense);
        dispatch(
          assignDocumentsToAgents({ docId, agentsLicense, agentType: checkagent != null ? 'Surveyor' : 'Agent' })
        );
      }
    });
  };

  const HandleSendMessageToBuyer = () => {
    Swal.fire({
      title: 'Do you want to send confirmation message to buyer?',
      showCancelButton: true,
      confirmButtonText: 'Send',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(sendLandDocumentStatusToBuyer({ docId, agentsLicense }));
      }
    });
  };

  useEffect(() => {
    dispatch(getDocument(docId));
    dispatch(fetchAgents());
    dispatch(fetchSurveyors());
    reset();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(reset());
    }, 3000);
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    if (isSuccess && message.includes('This Document has now been Assigned')) {
      toast.info(message, {
        onClose: () => {
          dispatch(reset());
        },
      });
    }

    if (isSuccess && message.includes('Message has been sent to Buyer')) {
      toast.info(message, {
        onClose: () => {
          dispatch(reset());
        },
      });
    }

    dispatch(reset());
  }, [isError, isSuccess, message, document]);

  return (
    <>
      <Helmet>
        <title> Document | Lock.your.Land Dashboard </title>
      </Helmet>

      <Container>
        <Box className="flex justify-between items-center flex-wrap" mb={1}>
          <Typography variant="h4" gutterBottom>
            Document Details
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            {document?.document?.AgentPaymentStatus !== 'Full' && (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                className="bg-blue-800"
                color="primary"
                size="medium"
                onClick={handleOpenModal}
              >
                {document?.document?.AgentPaymentStatus === 'Unpaid'
                  ? 'Initial Payment Transfer'
                  : 'Full Payment Transfer'}
              </Button>
            )}

            {/* Custom status box */}
            <Box
              sx={{
                padding: '6px 12px',
                borderRadius: '8px',
                backgroundColor:
                  document?.document?.AgentPaymentStatus === 'Full'
                    ? 'green'
                    : document?.document?.AgentPaymentStatus === 'Unpaid'
                    ? 'red'
                    : document?.document?.AgentPaymentStatus === 'Half'
                    ? 'orange'
                    : 'grey',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <img
                src={
                  document?.document?.AgentPaymentStatus === 'Full'
                    ? verifiedImg
                    : document?.document?.AgentPaymentStatus === 'Unpaid'
                    ? unverifiedImg
                    : document?.document?.AgentPaymentStatus === 'Half'
                    ? pendingImg
                    : null
                }
                alt="status icon"
                className="w-4 h-4"
              />
              <Typography variant="body2">
                {document?.document?.AgentPaymentStatus === 'Full'
                  ? 'Fully Paid'
                  : document?.document?.AgentPaymentStatus === 'Unpaid'
                  ? 'Unpaid'
                  : document?.document?.AgentPaymentStatus === 'Half'
                  ? 'Half Paid'
                  : 'Unknown'}
              </Typography>
            </Box>

            <Paystack
              publicKey="sk_test_c08ec29d71f4d4e4a5c855be7604af5ce15345bb"
              open={isModalOpen}
              document={document}
              fullname={`${document?.agent?.Firstname} ${document?.agent?.Lastname} `}
              amount={
                document?.document?.AgentPaymentStatus === 'Unpaid'
                  ? '90000'
                  : document?.document?.AgentUnbalancedPayment
              }
              handleClose={handleCloseModal}
              onTransferInitiated={handleTransferInitiated}
            />
          </Box>
        </Box>

        {isLoading && <Loader />}

        {document && (
          <Grid container spacing={2}>
            {/* Buyers details.. */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Buyers Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Fullname: <br /> <span className="font-bold">{document?.buyer?.Firstname}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Lastname: <br /> <span className="font-bold">{document?.buyer?.Lastname}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Gender: <br /> <span className="font-bold">{document?.buyer?.Gender}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Phone: <br /> <span className="font-bold">{document?.buyer?.Phone}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Email: <br /> <span className="font-bold">{document?.buyer?.Email}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{document?.buyer?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{document?.buyer?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{document?.buyer?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{document?.buyer?.Nin}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">Status</p>
                      <Chip
                        label={
                          document?.buyer?.VerifiedUser === 'completed'
                            ? 'Verified'
                            : document?.buyer?.VerifiedUser === 'Not Verified'
                            ? 'Not Verified'
                            : document?.buyer?.VerifiedUser === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          document?.buyer?.VerifiedUser === 'completed'
                            ? 'success'
                            : document?.buyer?.VerifiedUser === 'Not Verified'
                            ? 'error'
                            : document?.buyer?.VerifiedUser === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              document?.buyer?.VerifiedUser === 'completed'
                                ? verifiedImg
                                : document?.buyer?.VerifiedUser === 'Not Verified'
                                ? unverifiedImg
                                : document?.buyer?.VerifiedUser === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>

            {/* sellers information, the check is if the user isnt the seller, mostly if its for verifcation and not listings */}
            {document?.buyer?._id !== document?.seller?._id && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="">
                  <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                    <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                      Sellers Information: <br />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Fullname: <br /> <span className="font-bold">{document?.seller?.Firstname}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Lastname: <br /> <span className="font-bold">{document?.seller?.Lastname}</span>
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Gender: <br /> <span className="font-bold">{document?.seller?.Gender}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Phone: <br /> <span className="font-bold">{maskPhoneNumber(`${document?.user?.Phone}`)}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Email: <br /> <span className="font-bold">{maskEmail(`${document?.user?.Email}`)}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Country: <br /> <span className="font-bold">{document?.seller?.Country}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          State: <br /> <span className="font-bold">{document?.seller?.State}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Address: <br />{' '}
                          <span className="font-bold">{maskAddress(`${document?.seller?.Address}`)}</span>
                        </p>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        NIN: <br /> <span className="font-bold">{document?.seller?.Nin}</span>
                      </p>
                    </Grid> */}
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">Status</p>
                        <Chip
                          label={
                            document?.seller?.VerifiedUser === 'completed'
                              ? 'Verified'
                              : document?.seller?.VerifiedUser === 'Not Verified'
                              ? 'Not Verified'
                              : document?.seller?.VerifiedUser === 'pending'
                              ? 'Pending'
                              : ''
                          }
                          size="small"
                          color={
                            document?.seller?.VerifiedUser === 'completed'
                              ? 'success'
                              : document?.seller?.VerifiedUser === 'Not Verified'
                              ? 'error'
                              : document?.seller?.VerifiedUser === 'pending'
                              ? 'warning'
                              : ''
                          }
                          icon={
                            <img
                              src={
                                document?.seller?.VerifiedUser === 'completed'
                                  ? verifiedImg
                                  : document?.seller?.VerifiedUser === 'Not Verified'
                                  ? unverifiedImg
                                  : document?.seller?.VerifiedUser === 'pending'
                                  ? pendingImg
                                  : ''
                              }
                              alt="verified img"
                              className="w-4 h-4"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* sellers information, the check is if the user isnt the seller, mostly if its for verifcation and not listings */}
            {document?.buyer?._id === document?.seller?._id && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="">
                  <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                    <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                      Sellers Information: <br />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Fullname: <br /> <span className="font-bold">{document?.document?.SellersName}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Account Number: <br />{' '}
                          <span className="font-bold">{document?.document?.SellersAccountNumber}</span>
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Bank: <br /> <span className="font-bold">{document?.document?.SellersBank}</span>
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}

            {/* land documents and images  */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Images: <br />
                  </Typography>
                  {document?.land?.Images?.map((image, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-md border"
                    >
                      <div className="flex items-center space-x-2">
                        <ImageIcon className="text-gray-500 h-5 w-5" />
                        <span className="text-sm">Image {index + 1}</span>
                      </div>

                      <a href={image} target="_blank" rel="noreferrer">
                        <Button variant="outline" size="sm" className="text-xs">
                          View Image
                        </Button>
                      </a>
                    </div>
                  ))}
                </Box>

                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Documents: <br />
                  </Typography>
                  {document?.land?.Documents?.map((image, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-3 hover:bg-gray-50 rounded-md border"
                    >
                      <div className="flex items-center space-x-2">
                        <ImageIcon className="text-gray-500 h-5 w-5" />
                        <span className="text-sm">Document {index + 1}</span>
                      </div>

                      <a href={image} target="_blank" rel="noreferrer">
                        <Button variant="outline" size="sm" className="text-xs">
                          View Document
                        </Button>
                      </a>
                    </div>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* Property details */}
            <Grid item xs={12} md={12} lg={12} xl={6}>
              <Box className="">
                <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                  <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                    Land Information: <br />
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Country: <br /> <span className="font-bold">{document?.land?.Country}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        State: <br /> <span className="font-bold">{document?.land?.State}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Area: <br /> <span className="font-bold">{document?.land?.Area}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Address: <br /> <span className="font-bold">{document?.land?.Address}</span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Price: <br />{' '}
                        <span className="font-bold">
                          {document?.land?.Currency} {document?.land?.Price.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Escrow: <br />{' '}
                        <span className="font-bold">
                          {document?.land?.Currency} {document?.land?.Escrow.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Confirmed Price: <br />{' '}
                        <span className="font-bold">
                          {document?.land?.Currency} {document?.document?.AgentConfirmedPrice?.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Confirmed Escrow: <br />{' '}
                        <span className="font-bold">
                          {document?.land?.Currency} {document?.document?.AgentConfirmedEscrowPrice?.toLocaleString()}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        Size: <br />{' '}
                        <span className="font-bold">
                          {document?.land?.Size}
                          {document?.land?.Measurement}
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900">
                        UniqueId: <br /> <span className="font-bold">{document?.land?.UniqueId}</span>
                      </p>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900"> Document Status</p>
                      <Chip
                        label={
                          document?.document?.Status === 'completed'
                            ? 'Verified'
                            : document?.document?.Status === 'Not Verified'
                            ? 'Not Verified'
                            : document?.document?.Status === 'pending'
                            ? 'Pending'
                            : null
                        }
                        size="small"
                        color={
                          document?.document?.Status === 'completed'
                            ? 'success'
                            : document?.document?.Status === 'Not Verified'
                            ? 'error'
                            : document?.document?.Status === 'pending'
                            ? 'warning'
                            : null
                        }
                        icon={
                          <img
                            src={
                              document?.document?.Status === 'completed'
                                ? verifiedImg
                                : document?.document?.Status === 'Not Verified'
                                ? unverifiedImg
                                : document?.document?.Status === 'pending'
                                ? pendingImg
                                : null
                            }
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <p className="text-blue-900"> Document Message Status to Buyer</p>
                      <Chip
                        label={document?.document?.ValidationMessageStatus === true ? 'Message sent' : 'No Record'}
                        size="small"
                        color={document?.document?.ValidationMessageStatus === true ? 'success' : 'warning'}
                        icon={
                          <img
                            src={document?.document?.ValidationMessageStatus === true ? verifiedImg : pendingImg}
                            alt="verified img"
                            className="w-4 h-4"
                          />
                        }
                      />
                    </Grid>
                    {document?.agent ? (
                      <>
                        <Grid item xs={12} sm={12} md={4}>
                          <p className="text-blue-900">
                            Agent's Name: <br />{' '}
                            <span className="font-bold">{`${document?.agent?.Lastname}  ${document?.agent?.Firstname} `}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <p className="text-blue-900">
                            Agent's Email: <br /> <span className="font-bold">{`${document?.agent?.Email} `}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <p className="text-blue-900">
                            Agent's Phone: <br /> <span className="font-bold">{`${document?.agent?.Phone} `}</span>
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <p className="text-blue-900">
                            Agent Type: <br /> <span className="font-bold">{`${document?.document?.AgentType} `}</span>
                          </p>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Agent: <br /> <span className="font-bold">Not Assigned Yet</span>
                        </p>
                      </Grid>
                    )}

                    {document?.document?.Status === 'pending' && (
                      <Grid item xs={12} sm={12} md={12}>
                        <p className="text-blue-900">
                          {document?.agent?.Firstname ? '  Change Agent' : '  Assign Agent'}
                          : <br />
                        </p>
                        <Box sx={{ marginTop: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Agents</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={agentsLicense}
                              label="Agents"
                              onChange={handleChange}
                            >
                              {[...agents]?.map((agent, index) => (
                                <MenuItem key={index} value={agent.License || agent.AgentLicense}>
                                  {agent.Lastname} {agent.Firstname} {agent.VerifiedLicense ? '( Surveyor )' : ''}
                                  <h2 className="text-[11px] text-gray-500">{agent?.Email}</h2>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Box className="flex space-x-2 justify-between">
                            <Button
                              variant="outlined"
                              size="medium"
                              color="primary"
                              sx={{
                                textTransform: 'none',
                                marginTop: '3%',
                                fontWeight: 'bold',
                              }}
                              onClick={HandleAssign}
                            >
                              {isLoading ? 'Please wait...' : 'Confirm'}
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {document?.document?.DocumentProof && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900 mt-3">
                            Payment Due: <br />{' '}
                            <span className="font-bold">
                              {document?.document?.AgentUnbalancedPayment.toLocaleString()}
                            </span>
                          </p>
                        </Box>
                      </Grid>
                    )}
                    {document?.document?.DocumentProof && (
                      <Grid item xs={6} sm={6} md={4}>
                        <Box>
                          <p className="text-blue-900"> Payment Status</p>
                          <Chip
                            label={document?.document?.AgentUnbalancedPaymentStatus ? 'Paid' : 'Pending'}
                            size="small"
                            color={document?.document?.AgentUnbalancedPaymentStatus ? 'success' : 'warning'}
                            icon={
                              <img
                                src={document?.document?.AgentUnbalancedPaymentStatus ? verifiedImg : pendingImg}
                                alt="verified img"
                                className="w-4 h-4"
                              />
                            }
                          />
                        </Box>
                      </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={12}>
                      <p className="text-blue-900">
                        Comment: <br /> <span className="font-bold">{document?.document?.Comment}</span>
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    {document?.document?.AgentDeedOfSaleStatus === true && (
                      <Grid item sm={12} md={4}>
                        <Box className="mt-5">
                          <p className="text-blue-900">Draft Deed of Sale Document: </p>
                          <Link to={`${document?.document?.DraftDeedOfSale}`}>
                            <Box className="p-2 rounded-md bg-green-700 text-center cursor-pointer">
                              <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                Draft Deed of Sale Document
                              </Typography>
                            </Box>
                          </Link>
                          {document?.document?.BuyersDeedOfSaleStatus === false && (
                            <span className="text-red-500 text-[10px]">
                              (*) Awaiting Buyers Signature on Deed of Sale Document
                            </span>
                          )}
                        </Box>
                      </Grid>
                    )}

                    {document?.document?.FinalDeedOfSaleStatus === true && (
                      <Grid item sm={12} md={4}>
                        <Box className="mt-5">
                          <p className="text-blue-900">Final Deed of Sale Document: </p>
                          <Link to={`${document?.document?.FinalDeedOfSale}`}>
                            <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer">
                              <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                Final Deed of Sale Document
                              </Typography>
                            </Box>
                          </Link>
                        </Box>
                      </Grid>
                    )}
                  </Grid>

                  {document?.document?.Status !== 'pending' && (
                    <Box className="mt-5">
                      <p className="text-blue-900">Document Validation Receipts: </p>

                      <Grid container spacing={4}>
                        <Grid item sm={12} md={12}>
                          <Grid container spacing={2}>
                            <Grid item sm={12} md={4}>
                              <Link to={`${document?.document?.DocumentProof?.[0].LandRegistory}`}>
                                <Box className="p-2 rounded-md bg-green-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Land Registory Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${document?.document?.DocumentProof?.[1].ProbateRegistory}`}>
                                <Box className="p-2 rounded-md bg-blue-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                                    Probate Registory Document
                                  </Typography>
                                </Box>
                              </Link>
                            </Grid>
                            <Grid item sm={12} md={4}>
                              <Link to={`${document?.document?.DocumentProof?.[2].CacSearch}`}>
                                <Box className="p-2 rounded-md bg-cyan-700 text-center cursor-pointer">
                                  <Typography sx={{ fontSize: '14px', color: '#fff' }}>CAC Search</Typography>
                                </Box>
                              </Link>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Report
                        propertyType="Landed Property"
                        propertyId={document?.land?.UniqueId}
                        propertyLocation={`${document?.land?.Address}, ${document?.land?.Area}, ${document?.land?.State} , ${document?.land?.Country}`}
                        verificationDate={new Date(document?.document?.ValidatedTimeByAgent).toLocaleString()}
                        comments={document?.document?.Comment}
                        agent={document?.agent}
                      />
                    </Box>
                  )}
                </Box>
                {document?.document?.ValidationMessageStatus === false && document?.document?.Status !== 'pending' && (
                  <Button
                    variant="outlined"
                    size="medium"
                    color="success"
                    sx={{
                      textTransform: 'none',
                      marginTop: '3%',
                      fontWeight: 'bold',
                    }}
                    onClick={HandleSendMessageToBuyer}
                  >
                    {isLoading ? 'Please wait...' : 'Send Document Validation Status To Buyer'}
                  </Button>
                )}
              </Box>
            </Grid>

            {/* Survery details */}
            {document?.document?.Purpose === 'Survey' && (
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <Box className="">
                  <Box className="rounded-md shadow-lg px-3 py-5 bg-white mt-1">
                    <Typography varaint="h3" className=" mb-5 font-bold uppercase">
                      Survey Information: <br />
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Agents's Name: <br />{' '}
                          <span className="font-bold">{`${document?.agent?.Lastname}  ${document?.agent?.Firstname} `}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Agent's Email: <br /> <span className="font-bold">{`${document?.agent?.Email} `}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Agent's Phone: <br /> <span className="font-bold">{`${document?.agent?.Phone} `}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Agent's License: <br /> <span className="font-bold">{`${document?.agent?.License} `}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Previous Owner: <br /> <span className="font-bold">{document?.buyer?.Lastname}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">
                          Property Address: <br /> <span className="font-bold">{document?.buyer?.Address}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900">Status</p>
                        <Chip
                          label={
                            document?.buyer?.VerifiedUser === 'completed'
                              ? 'Verified'
                              : document?.buyer?.VerifiedUser === 'Not Verified'
                              ? 'Not Verified'
                              : document?.buyer?.VerifiedUser === 'pending'
                              ? 'Pending'
                              : null
                          }
                          size="small"
                          color={
                            document?.buyer?.VerifiedUser === 'completed'
                              ? 'success'
                              : document?.buyer?.VerifiedUser === 'Not Verified'
                              ? 'error'
                              : document?.buyer?.VerifiedUser === 'pending'
                              ? 'warning'
                              : null
                          }
                          icon={
                            <img
                              src={
                                document?.buyer?.VerifiedUser === 'completed'
                                  ? verifiedImg
                                  : document?.buyer?.VerifiedUser === 'Not Verified'
                                  ? unverifiedImg
                                  : document?.buyer?.VerifiedUser === 'pending'
                                  ? pendingImg
                                  : null
                              }
                              alt="verified img"
                              className="w-4 h-4"
                            />
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900"> Survey Status</p>
                        <Chip
                          label={
                            document?.document?.Status === 'completed'
                              ? 'Verified'
                              : document?.document?.Status === 'Not Verified'
                              ? 'Not Verified'
                              : document?.document?.Status === 'pending'
                              ? 'Pending'
                              : null
                          }
                          size="small"
                          color={
                            document?.document?.Status === 'completed'
                              ? 'success'
                              : document?.document?.Status === 'Not Verified'
                              ? 'error'
                              : document?.document?.Status === 'pending'
                              ? 'warning'
                              : null
                          }
                          icon={
                            <img
                              src={
                                document?.document?.Status === 'completed'
                                  ? verifiedImg
                                  : document?.document?.Status === 'Not Verified'
                                  ? unverifiedImg
                                  : document?.document?.Status === 'pending'
                                  ? pendingImg
                                  : null
                              }
                              alt="verified img"
                              className="w-4 h-4"
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <p className="text-blue-900"> Survery Status to Buyer</p>
                        <Chip
                          label={document?.document?.ValidationMessageStatus === true ? 'Message sent' : 'No Record'}
                          size="small"
                          color={document?.document?.ValidationMessageStatus === true ? 'success' : 'warning'}
                          icon={
                            <img
                              src={document?.document?.ValidationMessageStatus === true ? verifiedImg : pendingImg}
                              alt="verified img"
                              className="w-4 h-4"
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </Container>
    </>
  );
};

export default LandDocument;
