import axios from 'axios';
import { API_URL } from '../api';

const getDocument = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URL}/documents/property/${documentId}/${id}`, config);

  if (response.data) {
    localStorage.setItem('documentsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};

const assignDocumentsToAgents = async (token, id, documentId, License, AgentType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/documents/property/update_agent/${documentId}/${id}`,
    { License, AgentType },
    config
  );

  return response.data;
};

const assignDocumentsToSurveyors = async (token, id, documentId, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/documents/property/update_surveyor/${documentId}/${id}`,
    { License },
    config
  );

  return response.data;
};

const sendDocumentStatusToBuyer = async (token, id, documentId, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/documents/send_document_verifed_message/${documentId}/${id}`,
    { License },
    config
  );
  console.log(License);

  return response.data;
};

const sendSurveyStatusToBuyer = async (token, id, documentId, License) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(
    `${API_URL}/documents/property/sendmessage_survey/${documentId}/${id}`,
    { License },
    config
  );
  console.log(License);

  return response.data;
};

const UnverifyDocumentStatus = async (token, id, documentId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(`${API_URL}/documents/document/unverify/${documentId}/${id}`, '', config);

  if (response.data) {
    localStorage.setItem('documentsFromAdmin', JSON.stringify(response.data));
  }

  return response.data;
};
const documentService = {
  getDocument,
  assignDocumentsToAgents,
  assignDocumentsToSurveyors,
  UnverifyDocumentStatus,
  sendDocumentStatusToBuyer,
  sendSurveyStatusToBuyer,
};
export default documentService;
