// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const icon2 = (name) => <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Verified users',
    path: '/dashboard/verifiedusers',
    icon: icon2('ic_verified'),
  },
  {
    title: 'Pending users',
    path: '/dashboard/user',
    icon: icon2('ic_unverified'),
  },
  {
    title: 'Verified Agents',
    path: '/dashboard/verifiedAgents',
    icon: icon2('ic_agents'),
  },
  {
    title: 'Unverified Agents',
    path: '/dashboard/unVerifiedAgents',
    icon: icon2('ic_unverified_agent'),
  },
  {
    title: 'Verifed Documents',
    path: '/dashboard/verifiedDocuments',
    icon: icon2('ic_verified_docs'),
  },
  {
    title: 'Unverified Documents',
    path: '/dashboard/unverifiedDocuments',
    icon: icon2('ic_unverified_doc'),
  },
  {
    title: 'Resolved Surveys',
    path: '/dashboard/resolvedSurveys',
    icon: icon2('ic_surveyor'),
  },
  {
    title: 'Unresolved Surveys',
    path: '/dashboard/unresolvedSurveys',
    icon: icon2('ic_failed_survey'),
  },
  {
    title: 'Issued Survey',
    path: '/dashboard/issuedSurvey',
    icon: icon2('ic_issued'),
  },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
