import React, { useRef, useState } from 'react';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

const Report = ({ propertyType, comments, verificationDate, propertyLocation, propertyId, agent }) => {
  const [loader, setLoader] = useState();
  const downloadPDF = () => {
    const capture = document.querySelector('.actual-receipt');
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const doc = new JsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save('receipt.pdf');
    });
  };

  const url = `https://lockyourland.com/dashboard/verifydocs/${propertyId}`;

  return (
    <>
      <div className="real-estate-report bg-gray-100 p-6 actual-receipt mt-5">
        <h1 className="sm:text-2xl text-md font-bold mb-4">
          <span className="text-green-600 font-bold sm:text-2xl text-md">Lock.</span>
          <span className="font-bold text-neutral-900 sm:text-2xl text-md">your</span>
          <span className="text-blue-800 font-bold sm:text-2xl text-md">.land</span> Verification Report
        </h1>

        <section className="mb-8 flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold mb-2">Property Information</h2>
            <p>
              <strong>Property Type:</strong> {propertyType}
              <br />
              <strong>Location:</strong> {propertyLocation}
              <br />
              <strong>Property LYL Id:</strong> {propertyId}
              <br />
              <strong>Verification Date:</strong> {verificationDate}
            </p>
          </div>
          <div className="mt-4 flex items-center justify-center">
            <QRCode value={url} size={148} />
          </div>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Executive Summary</h2>
          <p>
            Provide a brief overview of the property's key details, verification findings, and any notable observations.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Table of Contents</h2>
          <ol className="list-decimal list-inside">
            <li>
              <strong>Introduction:</strong> Background information about the property and the purpose of verifying if
              the documents are true. Explain the significance of accurate property information and the need for
              thorough document verification in real estate transactions.
              <br />
              <br />
              <em>Purpose of Verification:</em> The primary goal is to ensure the accuracy and legitimacy of the
              provided documents, preventing potential legal and financial issues in the future.
              <br />
              <br />
              <em>Verification Methods:</em> Utilized a combination of document reviews, on-site inspections, and
              collaboration with relevant authorities to cross-verify information.
            </li>
            <li>
              <strong>Verification Process:</strong> Details about the verification methods and procedures followed to
              confirm the authenticity of the documents. Describe the steps taken to ensure accurate and reliable
              verification.
              <br />
              <br />
              <em>Document Reviews:</em> Thoroughly examined property deeds, contracts, and related documents to
              identify discrepancies or inconsistencies.
              <br />
              <br />
              <em>On-Site Inspections:</em> Conducted physical inspections of the property to validate information such
              as boundaries, structures, and infrastructure.
              <br />
              <br />
              <em>Collaboration:</em> Worked closely with land registries, probate registries, and relevant authorities
              to cross-verify ownership and legal details.
            </li>
            <li>
              <strong>Land Registry Verification:</strong> Checked and confirmed property ownership records and legal
              details with the local land registry.
            </li>
            <li>
              <strong>Probate Registry Verification:</strong> Verified the probate registry to confirm the legal status
              of the property and any associated inheritances or claims.
            </li>
            <li>
              <strong>CAC (Corporate Affairs Commission) Search:</strong> Conducted a search with the CAC to confirm the
              legal status and details of any corporate entities associated with the property.
            </li>
          </ol>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Comments and Observations</h2>
          <p>{comments}</p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-2">Appendices</h2>
          <p>
            Attach supporting documents, maps, photographs, and any additional information relevant to the verification
            for further validation. Explain the role of appendices in providing additional context and evidence for the
            verification process.
          </p>
        </section>

        <div className="mt-8 border-t pt-4">
          <div className="flex justify-between">
            <div>
              <p className="font-bold">Lawyers Licence: {agent?.AgentLicense}</p>
              <p className="font-bold">
                Lawyers Fullname: {agent?.Lastname}, {agent?.Firstname}
              </p>
              {/* Add space for the agent's signature */}
            </div>
          </div>

          {/* QR Code for Property Verification */}
        </div>
      </div>
      {/* <button className="mt-5 py-1 px-3 rounded-md bg-blue-800 text-blue-300" onClick={downloadPDF}>
        Download Report
      </button> */}
    </>
  );
};

export default Report;
